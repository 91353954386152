$ecogy-blue: #0eb6db;
$ecogy-red: #e22006;
$ecogy-green: #4caf50;
$ecogy-orange: orange;
$ecogy-white: #fff;
$ecogy-black: #000;
$ecogy-dark: #1f1f1f;
$ecogy-grey: #aaaeb3;
$ecogy-palegrey: #e9ecef;
$ecogy-lightgrey: #dae0e7;
$ecogy-mediumgrey: #959ca4;
$ecogy-darkgrey: #343c44;

html,
body,
#root {
  height: 100%;
  overflow: auto;
  background-color: $ecogy-black;
  color: $ecogy-white;
}

.app {
  height: 100%;
  background-color: $ecogy-darkgrey;

  a {
    color: $ecogy-blue;
  }

  .app-header {
    background-color: #1f1f1f;
    height: 60px;
    color: white;
    text-align: center;
  }

  .logo {
    height: auto;
    max-height: 100px;
    width: auto;
    max-width: 100%;
    margin-top: -17px;
  }

  @keyframes app-logo-spin {
    from {
      transform: rotateY(0deg);
    }

    to {
      transform: rotateY(360deg);
    }
  }

  .app-loading {
    text-align: center;
    margin-top: 200px;
    color: $ecogy-blue;
    font-size: xx-large;
  }

  .app-logo {
    animation: App-logo-spin infinite 5s linear;
    height: 200px;
  }

  .error {
    text-align: center;
    color: $ecogy-red;

    .error-title {
      padding-top: 10px;

      .material-icons {
        position: relative;
        top: 10px;
        color: $ecogy-red;
        font-size: 48px;
      }
    }

    .error-message {
      color: white;
    }
  }

  .message {
    text-align: center;
    color: $ecogy-grey;
    font-size: large;
    margin-top: 100px;
  }

  footer {
    background-color: $ecogy-black;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px;

    button.download {
      margin-right: 10px;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;

    th {
      color: $ecogy-blue;
    }

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;

      // word-wrap: break-word;
    }
  }
}

.modal-content {
  background-color: $ecogy-black;
}
